<script setup lang="ts">
import { computeUrl, computeScaledSrcSets } from '~/util/imageUtil'

const props = withDefaults(defineProps<{
  src: string,
  fallbackSrc?: string,
  alt?: string,
  lazy?: boolean,
  responsiveSize?: string,
  width?: number | string,
  height?: number | string,
  sizes?: {
    image: number,
    screen: number,
  }[] | string[] | number[],
  class?: string, // we need to reference this as props.class in the template to avoid a conflict with the class keyword
  sizeType?: string,
}>(), {
	lazy: true,
	responsiveSize: '100vw',
	sizes: undefined,
	fallbackSrc: '',
	width: undefined,
	height: undefined,
	sizeType: 'object-cover',
})

const useFallbackSrc = ref(false)

if (!props.alt) {
	console.warn('Image component is missing alt prop')
}

const computedSrc = computed(() => {
	return computeUrl(props.src, props.sizes?.[props.sizes.length - 1]?.image || props.sizes?.[props.sizes.length - 1] || 1920)
})
const computedSrcSets = computed(() => computeScaledSrcSets(props.src, props.sizes))
</script>

<template>
	<picture
		v-if="!useFallbackSrc"
		@error="useFallbackSrc = true"
	>
		<template
			v-for="(srcset, index) in computedSrcSets"
		>
			<source
				v-if="index < computedSrcSets.length - 1"
				:key="srcset.srcset"
				:srcset="srcset.srcset"
				:media="srcset.media"
				type="image/webp"
			>
			<img
				v-else
				:key="srcset.srcset"
				:class="props.class || `h-full w-full ${sizeType}`"
				:src="srcset.url"
				:alt="alt"
				:width="width"
				:height="height"
				:loading="lazy ? 'lazy' : 'eager'"
			>
		</template>
	</picture>
	<img
		v-else
		:class="props.class || `h-full w-full ${sizeType}`"
		:src="computedSrc"
		:alt="alt"
		:width="width"
		:height="height"
		:loading="lazy ? 'lazy' : 'eager'"
	>
</template>
