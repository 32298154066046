import { createCdnImageUrl } from 'ic-js-util'

export const computeUrl = (source = '', width?: string, format?: string): string => {
	return createCdnImageUrl(source, { width, format })
}

export function computeScaledSrcSets (src: string, breakpoints: string[] | number[] | {screen: number, image: number}[] = ['320', '360', '640', '720', '960', '1280', '1440', '1920'], largestSize?: number, format = 'webp') {
	const sizes = (largestSize ? [...breakpoints, largestSize] : breakpoints).map((size:string | number | {screen: number, image: number}) => {
		if (typeof size === 'number' || typeof size === 'string') {
			size = { screen: <number>size, image: <number>size }
		}
		let srcset = ''
		for (let i = 1; i <= 3; i++) {
			srcset += `${computeUrl(src, `${size.image * i}`, format)} ${i >= 1 ? `${i}x,` : ''}`
		}

		srcset = srcset.slice(0, -1)

		const media = `(max-width: ${size.screen}px)`

		const url = computeUrl(src, `${size.image}`, format)

		return {
			media,
			srcset,
			size,
			url,
		}
	})
	return sizes
}
